<template>
  <div class="page">
    <div style="position: relative; z-index: 99">
      <div style="position: relative; color: white; z-index: 99">
        <div
          style="
            padding: 20px 0px 10px 0px;
            text-align: center;
            font-size: 18px;
            color: #fff;
          "
        >
          根据您的资质情况！
        </div>
        <div style="padding: 0px 0px; text-align: center; font-size: 14px">
          已为您精选以下产品，仅限今日有效！
        </div>
      </div>

      <div class="card" style="margin: 0.32rem; padding: 0.32rem">
        <div
          style="display: flex; align-items: center; padding: 8px 0px 12px 0px"
        >
          <div style="padding-right: 18px">
            <div style="font-size: 18px; margin-bottom: 0.2rem">
              请勿接听任何
              <span style="color: #ff9800">境外来电</span>
              !
            </div>
            <div style="font-size: 14px; margin-bottom: 0.2rem">
              任何放款前以
              <span style="color: #ff9800">缴纳保证金，利息</span>
              等名义的行为均为<span style="color: #ff9800">诈骗</span>!
            </div>
            <div style="font-size: 14px">
              任何要求您提供<span style="color: #ff9800"
                >银行卡密码，验证码</span
              >等个人信息均为
              <span style="color: #ff9800">诈骗</span>
              !
            </div>
          </div>
          <img
            src="../../assets/img/baowei-Dbm4tdCQ.png"
            style="height: 80px; width: 80px"
            alt=""
          />
          <!-- <img src="../assets/img/baowei-Dbm4tdCQ.png" alt="" /> -->

          <!-- <van-image
            width="30"
            height="30"
            radius="100px"
            src="../../assets/img/baowei-Dbm4tdCQ.png"
          /> -->
        </div>

        <div
          v-for="item in compliant_produsts"
          :key="item.id"
          style="
            padding: 0.2rem 0rem;
            border-top: 1px solid #e0e0e0;
            border-bottom: 1px solid #e0e0e0;
          "
        >
          <div style="display: flex">
            <van-checkbox
              style="padding: 0px 16px"
              v-model="item.is_select"
            ></van-checkbox>

            <div style="flex: 1">
              <div>
                <div
                  style="
                    margin-top: 8px;
                    margin-right: 0.32rem;
                    display: flex;
                    align-items: center;
                  "
                >
                  <van-image
                    width="30"
                    height="30"
                    radius="100px"
                    :src="item['organization']['logo_img']"
                  />

                  <div
                    style="
                      font-size: 18px;
                      color: #333;
                      flex: 1;
                      font-weight: 700;
                      margin-left: 10px;
                    "
                  >
                    {{ item.name }}
                  </div>
                </div>
              </div>

              <div style="font-size: 14px; color: #757575; font-weight: 600">
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    margin-top: 8px;
                    font-weight: 700;
                    margin-bottom: 8px;
                  "
                >
                  <div style="color: #212121">
                    助贷机构： {{ item["organization"]["name"] }}
                  </div>
                </div>

                <div style="margin-bottom: 4px">
                  最高额度:<span style="color: #616161; font-weight: 700"
                    >20万</span
                  >
                </div>

                <div style="margin-bottom: 4px">
                  利率：<span style="color: #ff5252; font-weight: 700"
                    >年化7.2%</span
                  >
                  起（以实际审核为准）
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style="text-align: center">
          <label
            style="
              display: flex;
              margin: 12px 10px 12px 10px;
              justify-content: center;
            "
          >
            <van-checkbox
              v-model="popup_contract.status"
              :icon-size="14"
              style="
                padding: 0px 10px 0px 15px;
                white-space: nowrap;
                align-self: baseline;
              "
            >
            </van-checkbox>
            <span
              class="weui-agree__text"
              style="font-size: 12px; text-align: left"
            >
              <span>我已阅读并同意</span>
              <a
                style="color: #204afa"
                @click="openPopupContract(true, 'loanInformationLetter')"
                >《贷款知情书》
              </a>
              <!-- <a style="color: #204afa" @click="openPopupContract(true, '', '')"
              >《风险告知书》</a -->
            </span>
          </label>
        </div>

        <div class="bottomBtn">
          <van-button round block color="#204afa" @click="onSubmit"
            >立即借款</van-button
          >
        </div>
      </div>

      <div
        style="
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          background-color: rgb(32, 74, 250);
          height: 140px;
          border-radius: 0px 0px 25% 25%;
        "
      ></div>
    </div>

    <!-- 协议 底部弹出 -->
    <van-popup
      class="popup_contract"
      v-model:show="popup_contract.show_status"
      position="bottom"
      :style="{ height: '80%' }"
    >
      <div class="content">
        <div class="head">
          <div style="flex: 1; font-size: 20px">{{ popup_contract.title }}</div>
          <van-icon
            @click="openPopupContract(false)"
            name="cross"
            size="18"
            color="#757575"
          />
        </div>

        <van-loading
          v-if="popup_contract.loading"
          style="text-align: center"
          color="#1989fa"
        />
        <div v-else class="text" v-html="popup_contract.text"></div>
        <div class="bottom">
          <van-button
            @click="agreeContract"
            type="primary"
            color="#204afa"
            style="margin: 60px 0px 20px 0px; width: 80%"
            >同意本协议</van-button
          >
        </div>
      </div>
    </van-popup>

    <transition name="van-fade">
      <div
        v-if="loadingPage"
        style="position: fixed; top: 0; z-index: 999; background-color: #fff"
      >
        <div class="limitAssess">
          <Nav
            backgroundColor="rgba(1,1,1,0)"
            textColor="black"
            title="额度评估"
          />

          <div class="limitIcon">
            <div class="iconOut">
              <img src="../../assets/limitAssess.png" alt="" srcset="" />
            </div>
          </div>
          <div class="limitTitle">尊敬的用户，您已完成认证</div>
          <div class="limitDesc">正在为您重新评估额度......</div>

          <div class="limitItem">
            <div>
              <van-loading v-if="countNum > 5" type="spinner" size="0.373rem" />
              <img v-else src="../../assets/right.png" alt="" srcset="" />
              <div class="limitItemBox">
                <van-icon name="records" size="0.373rem" color="#1D47FB" /><span
                  >基本信息</span
                >
              </div>
            </div>
            <div>
              <van-loading v-if="countNum > 3" type="spinner" size="0.373rem" />
              <img v-else src="../../assets/right.png" alt="" srcset="" />
              <div class="limitItemBox">
                <van-icon name="user-o" size="0.373rem" color="#1D47FB" /><span
                  >身份信息</span
                >
              </div>
            </div>
            <div>
              <van-loading v-if="countNum > 1" type="spinner" size="0.373rem" />
              <img v-else src="../../assets/right.png" alt="" srcset="" />
              <div class="limitItemBox">
                <van-icon
                  name="balance-o"
                  size="0.373rem"
                  color="#1D47FB"
                /><span>匹配资方</span>
              </div>
            </div>
          </div>

          <div class="awaitText">
            <van-count-down>
              <template #default="timeData">
                <span class="block">预计等待时间：3S</span>
              </template>
            </van-count-down>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { base64_encode, base64_decode } from "@/utils/base64Util.js";
import {
  apiClueMatching,
  apiClueAdvertising,
  apiClueSubmitApplications,
} from "@/api/apiClue.js";
import { apiConfigAgreement } from "@/api/apiConfig.js";
export default {
  name: "product",
  components: {},
  data() {
    return {
      loadingPage: true,
      countNum: 6,
      compliant_produsts: [],

      popup_contract: {
        title: "",
        text: "",
        status: false,
        show_status: false,
        loading: true,
      },
    };
  },

  created() {
    this.reqClueMatching();
  },
  methods: {
    //获取匹配的产品列表
    reqClueMatching() {
      const _this = this;
      apiClueMatching({
        mobile_md: this.$route.query.mobile_md,
        id: this.$route.query.id,
      }).then(({ data }) => {
        if (+data.code === 1) {
          var stage = data["data"]["stage"];
          if (stage != "data_filled") {
            _this.$router.push({
              path: "applySumbit",
              query: {
                id: _this.$route.query.id,
                cid: this.$route.query.cid,
                mobile_md: _this.$route.query.mobile_md,
                status: stage,
              },
            });
          }
          _this.loadingPage = false;
          var compliant_produsts = data.data.compliant_produsts;

          var item = [];
          for (var c = 0; c < compliant_produsts.length; c++) {
            compliant_produsts[c]["is_select"] = true;
          }

          // var new_compliant_produsts = [];
          // for (var c = 0; c < 10; c++) {
          //   new_compliant_produsts[c] = item;
          // }

          _this.compliant_produsts = compliant_produsts;
        } else {
          this.$toast(data.code);
        }
      });
    },
    // 打开协议弹框
    openPopupContract(show_status, key) {
      var popup_contract = this.popup_contract;
      this.popup_contract.show_status = show_status;
      if (show_status) {
        popup_contract.key = key;
        this.popup_contract = popup_contract;
        this.getContracts(key);
      }
    },
    // 获取协议
    getContracts(key) {
      const _this = this;
      _this.popup_contract.loading = true;
      apiConfigAgreement({
        key: key,
        mobile_md: this.$route.query.mobile_md,
      }).then(({ data }) => {
        let { stage, id } = data.data ? data.data : {};
        if (+data.code === 1) {
          var res_data = data.data;
          var details = res_data["details"];
          if (details) {
            var popup_contract = _this.popup_contract;
            popup_contract.title = details["title_cn"];
            popup_contract.text = base64_decode(details.value);
            popup_contract.loading = false;
            _this.popup_contract = popup_contract;
          }
        } else {
          this.$toast(data.msg);
        }
      });
    },
    // 同意协议
    agreeContract() {
      var popup_contract = this.popup_contract;
      popup_contract.status = true;
      popup_contract.show_status = false;
      this.popup_contract = popup_contract;
    },
    // 提交选择的产品
    onSubmit() {
      if (this.popup_contract.status == false) {
        this.openPopupContract(true, "loanInformationLetter");
        return false;
      }

      var p_ids = [];
      var compliant_produsts = this.compliant_produsts;
      for (var c = 0; c < compliant_produsts.length; c++) {
        if (compliant_produsts[c]["is_select"]) {
          p_ids.push(compliant_produsts[c]["id"]);
        }
      }
      if(!p_ids.length){
        this.$toast('请先选择产品！');
        return false;
      }
      var str_p_ids = p_ids.join(",");
      const _this = this;
      apiClueSubmitApplications({
        id: this.$route.query.id,
        mobile_md: this.$route.query.mobile_md,
        p_ids: str_p_ids,
      }).then(({ data }) => {
        if (+data.code === 1) {
          var stage = data["data"]["stage"];
          _this.$router.push({
            path: "applySumbit",
            query: {
              id: _this.$route.query.id,
              cid: this.$route.query.cid,
              mobile_md: _this.$route.query.mobile_md,
              status: stage,
            },
          });
        } else {
          this.$toast(data.code);
        }
      });
    },
  },
};
</script>

<style lang="less">
.page {
  // position: fixed;
  // height: 100%;
  // width: 100%;
  // background-color: #f5f5f5;
}
.card {
  z-index: 99;
  position: relative;
  border-radius: 12px;
  background-color: #fff;
}

.limitAssess {
  .limitIcon {
    display: flex;
    justify-content: center;
    margin: 1.1467rem 1.1467rem 0.586rem 1.1467rem;
    .iconOut {
      width: 2.4rem;
      height: 2.4rem;
      background: rgba(55, 142, 255, 0.2);
      border-radius: 2.4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 1.2rem;
      }
    }
  }
  .limitTitle {
    font-size: 0.426rem;
    text-align: center;
  }
  .limitDesc {
    margin-top: 0.213rem;
    font-size: 0.32rem;
    color: #666;
    text-align: center;
  }
  .limitItem {
    width: 8rem;
    margin: 1.06rem 1rem 0.72rem;
    padding-top: 0.693rem;
    background: #ffffff;
    box-shadow: 0px 0 0.4rem 1px rgba(110, 110, 110, 0.1);
    border-radius: 0.266rem;
    text-align: center;
    img {
      width: 0.373rem;
      height: 0.373rem;
    }
    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 0.693rem;
      color: #333;
      .limitItemBox {
        width: 3.333rem;
        height: 0.96rem;
        margin-left: 0.266rem;
        line-height: 0.96rem;
        background: #f9f9f9;
        border-radius: 1rem;
        text-align: center;
        span {
          display: inline-block;
          margin-left: 0.32rem;
        }
      }
    }
  }
  .awaitText {
    margin-bottom: 1rem;
    text-align: center;
    span {
      color: #999;
    }
  }
}

.popup_contract {
  .content {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    display: flex;
    flex-direction: column;

    .head {
      min-height: 64px;
      padding: 0px 20px;
      display: flex;
      align-items: center;
    }

    .text {
      padding: 20px;
      overflow-y: auto;
      padding-bottom: 120px;
    }

    .bottom {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      text-align: center;
      background-image: linear-gradient(
        to bottom,
        #ffffff00,
        rgba(255, 255, 255, 0.5),
        white,
        white,
        white,
        white
      );
    }
  }
}

.bottomBtn {
  margin: 12px 0.72rem 0.4rem 0.72rem;
}
</style>
